import { default as api_45legacyEIrhZ0msDOMeta } from "/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsp2KtkFWPWjMeta } from "/codebuild/output/src4016038410/src/horizon/pages/awards.vue?macro=true";
import { default as programs2tUWhjNKexMeta } from "/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93qZf2aJgzKRMeta } from "/codebuild/output/src4016038410/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93jkQHP0ummrMeta } from "/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexVwWz0EuEyAMeta } from "/codebuild/output/src4016038410/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta } from "/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93cW1rFc5YLzMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_9338QTVuio5TMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutDEXxW3CMLKMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/about.vue?macro=true";
import { default as index7c8I7pLkStMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productdWoCMteBx0Meta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexevLB6rfKJlMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as sales7379jLhKldMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archive8bLQb3meuEMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexPpgf7EtLwMMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexicSjJ8UbIwMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_936Fow8YCanRMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexhSNZQk8tJEMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as diversity9ood82yHk4Meta } from "/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue?macro=true";
import { default as index4N4KUKaIk6Meta } from "/codebuild/output/src4016038410/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencySVNPPhVB2aMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safety1Tj0T9DXmGMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilityGTnRTCORVPMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45news4yTFEJXgUrMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as index9BN4z5T1cDMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesAZcSpSRJUrMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacyi1URLlBCewMeta } from "/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45tool5XDdeBbBCQMeta } from "/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93tp8cuvJyvOMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as indexXLKTSYtXXmMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_939gH7O1iBoPMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93txFTCLLPFhMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexDzigMZkXYkMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93IIetYuF27kMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondtCY2EYY3bGMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performancetdPuIfBxn2Meta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceTSCmlt96FBMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexPvSucrEbbrMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetjeE6lH8um6Meta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceUeHXSen2o5Meta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performance2gL99wJY48Meta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovator36cxWyhPLoMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexxew4DhBtoNMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetWGHiSU7GRBMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetAgmVS8ZGUsMeta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as index6bwMfVCiS3Meta } from "/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93GEWVWrxKePMeta } from "/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93RjKnFZlWGCMeta } from "/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue?macro=true";
import { default as _91slug_931NjcDKH3QtMeta } from "/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexL5bz7JM2MJMeta } from "/codebuild/output/src4016038410/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45appZ1NP45PLZVMeta } from "/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93YJcdsOeI5aMeta } from "/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93w4HzKR829bMeta } from "/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_938r23lxRqNkMeta } from "/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93yHeSGgQ0nAMeta } from "/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_933pGCIQicH2Meta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talent5zmXKi99utMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexY50pqSdSHzMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsQrdYNwAw4oMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsLRqdbM8aPhMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexNV1f3CbYMNMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programkJDhNzLE4zMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roiiFSWpD2xqlMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as index2tl8IWx6TsMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45future7GDQ2ZYQlUMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamssOWsrnXSiMMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexmBryCdRRNQMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45data0xC0WHSEKnMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93P7ifQ3uj5yMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexb6CFjE0o8wMeta } from "/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexNsfnByPigFMeta } from "/codebuild/output/src4016038410/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_935Cr8NjqMcoMeta } from "/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93MY2aqomYpzMeta } from "/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexkKGTW20LYhMeta } from "/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiesQVqMEJ7UWlMeta } from "/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexvvM5FLv7E3Meta } from "/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexbaat0epN5gMeta } from "/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue?macro=true";
import { default as _91slug_93A5H5axKDb4Meta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue?macro=true";
import { default as candidate_45privacy_45noticec3LtBX6r4CMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentUCU2vWELnjMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyCsQgWEFtT8Meta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumBKWRzXvBAFMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termshGY1Mq4D2zMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warranty58TxA39hxPMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45sla3w02h0BuQZMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductxUkyoF8IokMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceumFtPlxfkvMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyeN77NXFxKLMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdataBQFpLZhMm3Meta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as security02CKStO1hRMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportfkthYh3BPXMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45serviceql0NTjn89BMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesRBRkuiZJbfMeta } from "/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as operatesmarterDAddnge9LPMeta } from "/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue?macro=true";
import { default as pricingqCCVWG7Y8wMeta } from "/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93kEahpPnyGPMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93H0c7ROUyyOMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingrNqSKL8LdBMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsj0LMCtEXU2Meta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionsvf23pdQHPMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementg7CnttcrYEMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwideyYL1pRonX1Meta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingPq4xaT2uusMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as _91_46_46_46slug_93VALZKcIQXdMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue?macro=true";
import { default as pricingWcsctnowSkMeta } from "/codebuild/output/src4016038410/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93zs47GbDHdcMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93Dg2SPqg8p4Meta } from "/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingpu2ue9sVq1Meta } from "/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackinghfLu5EZ21wMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringo0N3UzHUWIMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailersHPdjxki0YRMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingzoDV8aeINiMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexB6vdrIYYnRMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93P2MxtAbrZ8Meta } from "/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as index0AdVMyDVTrMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingSa2wetbIloMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexpRJdgHWVDbMeta } from "/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as racingpuNhpUX7fQMeta } from "/codebuild/output/src4016038410/src/horizon/pages/racing.vue?macro=true";
import { default as referralshz8bceV91ZMeta } from "/codebuild/output/src4016038410/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93oqSQN4OztEMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93bTuPEQSTsjMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetsVVcRSMfTGmMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93pOd4jOG7DAMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centerYRJVpT29oUMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as one_45click_45unsubscribeUgT01mqYI0Meta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue?macro=true";
import { default as _91slug_93tSCo0MpOCnMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93JjJ49xDyyTMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as index1quA9Bg6aKMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexzIAUVcFPNhMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93tk99ghg8pUMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_937kmrwA1AANMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as indexmoqMalFzpFMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsara7s26ek8oQSMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programscogphUxmoWMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as indexuLyfYcNNlHMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45services3DVKo2keB7Meta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsarampIR689z6QMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourLEhFGmUQznMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosGvfFCMsgnLMeta } from "/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexJU1L5jRmACMeta } from "/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesSumIAIQkYTMeta } from "/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summittP6rIjwYDpMeta } from "/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93dgVWfNQVa6Meta } from "/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93TKOFy2J9FGMeta } from "/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexYPlKyiIJ4rMeta } from "/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securitygSLizbRiiMMeta } from "/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spaceIbKPYvLXSMMeta } from "/codebuild/output/src4016038410/src/horizon/pages/space.vue?macro=true";
import { default as sparkzCtk3DqEMHMeta } from "/codebuild/output/src4016038410/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93Fohd5uycLUMeta } from "/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93AUbjG4AwEIMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93ndqDmzE6UBMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyTGzBhsC6K7Meta } from "/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqS70yqE1E1JMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanish7wPKxwJ0odMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45traininggCpLid4DwgMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexGLTTrb95lPMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/index.vue?macro=true";
import { default as industriallyXqBDcvITMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementnNouAIYMCXMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyZIflTRUNTyMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexyFwbkOxSRmMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuresP9VyFDd1fnMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listtLEntDpwoyMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlestbIXKsxl1UMeta } from "/codebuild/output/src4016038410/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pagebY3EgAqEAFMeta } from "/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingZEEcdBQibFMeta } from "/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralfXVMNuF7IdMeta } from "/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyoubiCBFs4W8YMeta } from "/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue?macro=true";
import { default as eliteuDLhu14iAjMeta } from "/codebuild/output/src4016038410/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as pluseGl8z4y8o4Meta } from "/codebuild/output/src4016038410/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierSI6XfkQrC6Meta } from "/codebuild/output/src4016038410/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starternD0TMlFbsUMeta } from "/codebuild/output/src4016038410/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processnLvepd2Bz8Meta } from "/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue?macro=true";
import { default as support71hxqgk7SvMeta } from "/codebuild/output/src4016038410/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93vN4HkXfu5lMeta } from "/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexs8Yma2MWk9Meta } from "/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93bB98LtK8z4Meta } from "/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
import { default as component_45stubdecMsWe93GMeta } from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubdecMsWe93G } from "/codebuild/output/src4016038410/src/horizon/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___en-US",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___ca",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___nl",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___uk",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___de",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/de/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___mx",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___fr",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyEIrhZ0msDOMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyEIrhZ0msDOMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyEIrhZ0msDOMeta || {},
    alias: api_45legacyEIrhZ0msDOMeta?.alias || [],
    redirect: api_45legacyEIrhZ0msDOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___en-US",
    path: awardsp2KtkFWPWjMeta?.path ?? "/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___ca",
    path: awardsp2KtkFWPWjMeta?.path ?? "/ca/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___nl",
    path: awardsp2KtkFWPWjMeta?.path ?? "/nl/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___uk",
    path: awardsp2KtkFWPWjMeta?.path ?? "/uk/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___de",
    path: awardsp2KtkFWPWjMeta?.path ?? "/de/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___mx",
    path: awardsp2KtkFWPWjMeta?.path ?? "/mx/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___fr",
    path: awardsp2KtkFWPWjMeta?.path ?? "/fr/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsp2KtkFWPWjMeta?.name ?? "awards___fr-ca",
    path: awardsp2KtkFWPWjMeta?.path ?? "/fr-ca/awards",
    meta: awardsp2KtkFWPWjMeta || {},
    alias: awardsp2KtkFWPWjMeta?.alias || [],
    redirect: awardsp2KtkFWPWjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___en-US",
    path: programs2tUWhjNKexMeta?.path ?? "/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___ca",
    path: programs2tUWhjNKexMeta?.path ?? "/ca/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___nl",
    path: programs2tUWhjNKexMeta?.path ?? "/nl/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___uk",
    path: programs2tUWhjNKexMeta?.path ?? "/uk/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___de",
    path: programs2tUWhjNKexMeta?.path ?? "/de/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___mx",
    path: programs2tUWhjNKexMeta?.path ?? "/mx/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___fr",
    path: programs2tUWhjNKexMeta?.path ?? "/fr/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programs2tUWhjNKexMeta?.name ?? "benefits-programs___fr-ca",
    path: programs2tUWhjNKexMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programs2tUWhjNKexMeta || {},
    alias: programs2tUWhjNKexMeta?.alias || [],
    redirect: programs2tUWhjNKexMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZf2aJgzKRMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93qZf2aJgzKRMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93qZf2aJgzKRMeta || {},
    alias: _91slug_93qZf2aJgzKRMeta?.alias || [],
    redirect: _91slug_93qZf2aJgzKRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZf2aJgzKRMeta?.name ?? "blog-slug___ca",
    path: _91slug_93qZf2aJgzKRMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93qZf2aJgzKRMeta || {},
    alias: _91slug_93qZf2aJgzKRMeta?.alias || [],
    redirect: _91slug_93qZf2aJgzKRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZf2aJgzKRMeta?.name ?? "blog-slug___mx",
    path: _91slug_93qZf2aJgzKRMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93qZf2aJgzKRMeta || {},
    alias: _91slug_93qZf2aJgzKRMeta?.alias || [],
    redirect: _91slug_93qZf2aJgzKRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZf2aJgzKRMeta?.name ?? "blog-slug___uk",
    path: _91slug_93qZf2aJgzKRMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93qZf2aJgzKRMeta || {},
    alias: _91slug_93qZf2aJgzKRMeta?.alias || [],
    redirect: _91slug_93qZf2aJgzKRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZf2aJgzKRMeta?.name ?? "blog-slug___fr",
    path: _91slug_93qZf2aJgzKRMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93qZf2aJgzKRMeta || {},
    alias: _91slug_93qZf2aJgzKRMeta?.alias || [],
    redirect: _91slug_93qZf2aJgzKRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qZf2aJgzKRMeta?.name ?? "blog-slug___de",
    path: _91slug_93qZf2aJgzKRMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93qZf2aJgzKRMeta || {},
    alias: _91slug_93qZf2aJgzKRMeta?.alias || [],
    redirect: _91slug_93qZf2aJgzKRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___nl",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/nl/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jkQHP0ummrMeta?.name ?? "blog-category-slug___fr-ca",
    path: _91slug_93jkQHP0ummrMeta?.path ?? "/fr-ca/blog/category/:slug()",
    meta: _91slug_93jkQHP0ummrMeta || {},
    alias: _91slug_93jkQHP0ummrMeta?.alias || [],
    redirect: _91slug_93jkQHP0ummrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVwWz0EuEyAMeta?.name ?? "blog___en-US",
    path: indexVwWz0EuEyAMeta?.path ?? "/blog",
    meta: indexVwWz0EuEyAMeta || {},
    alias: indexVwWz0EuEyAMeta?.alias || [],
    redirect: indexVwWz0EuEyAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwWz0EuEyAMeta?.name ?? "blog___ca",
    path: indexVwWz0EuEyAMeta?.path ?? "/ca/blog",
    meta: indexVwWz0EuEyAMeta || {},
    alias: indexVwWz0EuEyAMeta?.alias || [],
    redirect: indexVwWz0EuEyAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwWz0EuEyAMeta?.name ?? "blog___mx",
    path: indexVwWz0EuEyAMeta?.path ?? "/mx/blog",
    meta: indexVwWz0EuEyAMeta || {},
    alias: indexVwWz0EuEyAMeta?.alias || [],
    redirect: indexVwWz0EuEyAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwWz0EuEyAMeta?.name ?? "blog___uk",
    path: indexVwWz0EuEyAMeta?.path ?? "/uk/blog",
    meta: indexVwWz0EuEyAMeta || {},
    alias: indexVwWz0EuEyAMeta?.alias || [],
    redirect: indexVwWz0EuEyAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwWz0EuEyAMeta?.name ?? "blog___fr",
    path: indexVwWz0EuEyAMeta?.path ?? "/fr/blog",
    meta: indexVwWz0EuEyAMeta || {},
    alias: indexVwWz0EuEyAMeta?.alias || [],
    redirect: indexVwWz0EuEyAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwWz0EuEyAMeta?.name ?? "blog___de",
    path: indexVwWz0EuEyAMeta?.path ?? "/de/blog",
    meta: indexVwWz0EuEyAMeta || {},
    alias: indexVwWz0EuEyAMeta?.alias || [],
    redirect: indexVwWz0EuEyAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondubMeMgJ9REMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cW1rFc5YLzMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93cW1rFc5YLzMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93cW1rFc5YLzMeta || {},
    alias: _91slug_93cW1rFc5YLzMeta?.alias || [],
    redirect: _91slug_93cW1rFc5YLzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___en-US",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___ca",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___nl",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___uk",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___de",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___mx",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___fr",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338QTVuio5TMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_9338QTVuio5TMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_9338QTVuio5TMeta || {},
    alias: _91slug_9338QTVuio5TMeta?.alias || [],
    redirect: _91slug_9338QTVuio5TMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___en-US",
    path: aboutDEXxW3CMLKMeta?.path ?? "/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___ca",
    path: aboutDEXxW3CMLKMeta?.path ?? "/ca/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___nl",
    path: aboutDEXxW3CMLKMeta?.path ?? "/nl/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___uk",
    path: aboutDEXxW3CMLKMeta?.path ?? "/uk/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___de",
    path: aboutDEXxW3CMLKMeta?.path ?? "/de/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___mx",
    path: aboutDEXxW3CMLKMeta?.path ?? "/mx/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___fr",
    path: aboutDEXxW3CMLKMeta?.path ?? "/fr/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutDEXxW3CMLKMeta?.name ?? "company-about___fr-ca",
    path: aboutDEXxW3CMLKMeta?.path ?? "/fr-ca/company/about",
    meta: aboutDEXxW3CMLKMeta || {},
    alias: aboutDEXxW3CMLKMeta?.alias || [],
    redirect: aboutDEXxW3CMLKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___en-US",
    path: index7c8I7pLkStMeta?.path ?? "/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___ca",
    path: index7c8I7pLkStMeta?.path ?? "/ca/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___nl",
    path: index7c8I7pLkStMeta?.path ?? "/nl/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___uk",
    path: index7c8I7pLkStMeta?.path ?? "/uk/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___de",
    path: index7c8I7pLkStMeta?.path ?? "/de/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___mx",
    path: index7c8I7pLkStMeta?.path ?? "/mx/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___fr",
    path: index7c8I7pLkStMeta?.path ?? "/fr/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index7c8I7pLkStMeta?.name ?? "company-careers-benefits___fr-ca",
    path: index7c8I7pLkStMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: index7c8I7pLkStMeta || {},
    alias: index7c8I7pLkStMeta?.alias || [],
    redirect: index7c8I7pLkStMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productdWoCMteBx0Meta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productdWoCMteBx0Meta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productdWoCMteBx0Meta || {},
    alias: engineering_45and_45productdWoCMteBx0Meta?.alias || [],
    redirect: engineering_45and_45productdWoCMteBx0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___en-US",
    path: indexevLB6rfKJlMeta?.path ?? "/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___ca",
    path: indexevLB6rfKJlMeta?.path ?? "/ca/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___nl",
    path: indexevLB6rfKJlMeta?.path ?? "/nl/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___uk",
    path: indexevLB6rfKJlMeta?.path ?? "/uk/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___de",
    path: indexevLB6rfKJlMeta?.path ?? "/de/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___mx",
    path: indexevLB6rfKJlMeta?.path ?? "/mx/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___fr",
    path: indexevLB6rfKJlMeta?.path ?? "/fr/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexevLB6rfKJlMeta?.name ?? "company-careers-departments___fr-ca",
    path: indexevLB6rfKJlMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexevLB6rfKJlMeta || {},
    alias: indexevLB6rfKJlMeta?.alias || [],
    redirect: indexevLB6rfKJlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___en-US",
    path: sales7379jLhKldMeta?.path ?? "/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___ca",
    path: sales7379jLhKldMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___nl",
    path: sales7379jLhKldMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___uk",
    path: sales7379jLhKldMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___de",
    path: sales7379jLhKldMeta?.path ?? "/de/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___mx",
    path: sales7379jLhKldMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___fr",
    path: sales7379jLhKldMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales7379jLhKldMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: sales7379jLhKldMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: sales7379jLhKldMeta || {},
    alias: sales7379jLhKldMeta?.alias || [],
    redirect: sales7379jLhKldMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archive8bLQb3meuEMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archive8bLQb3meuEMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archive8bLQb3meuEMeta || {},
    alias: emerging_45talent_45archive8bLQb3meuEMeta?.alias || [],
    redirect: emerging_45talent_45archive8bLQb3meuEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___en-US",
    path: indexPpgf7EtLwMMeta?.path ?? "/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___ca",
    path: indexPpgf7EtLwMMeta?.path ?? "/ca/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___nl",
    path: indexPpgf7EtLwMMeta?.path ?? "/nl/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___uk",
    path: indexPpgf7EtLwMMeta?.path ?? "/uk/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___de",
    path: indexPpgf7EtLwMMeta?.path ?? "/de/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___mx",
    path: indexPpgf7EtLwMMeta?.path ?? "/mx/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___fr",
    path: indexPpgf7EtLwMMeta?.path ?? "/fr/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPpgf7EtLwMMeta?.name ?? "company-careers___fr-ca",
    path: indexPpgf7EtLwMMeta?.path ?? "/fr-ca/company/careers",
    meta: indexPpgf7EtLwMMeta || {},
    alias: indexPpgf7EtLwMMeta?.alias || [],
    redirect: indexPpgf7EtLwMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___en-US",
    path: indexicSjJ8UbIwMeta?.path ?? "/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___ca",
    path: indexicSjJ8UbIwMeta?.path ?? "/ca/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___nl",
    path: indexicSjJ8UbIwMeta?.path ?? "/nl/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___uk",
    path: indexicSjJ8UbIwMeta?.path ?? "/uk/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___de",
    path: indexicSjJ8UbIwMeta?.path ?? "/de/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___mx",
    path: indexicSjJ8UbIwMeta?.path ?? "/mx/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___fr",
    path: indexicSjJ8UbIwMeta?.path ?? "/fr/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexicSjJ8UbIwMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexicSjJ8UbIwMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexicSjJ8UbIwMeta || {},
    alias: indexicSjJ8UbIwMeta?.alias || [],
    redirect: indexicSjJ8UbIwMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936Fow8YCanRMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_936Fow8YCanRMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_936Fow8YCanRMeta || {},
    alias: _91slug_936Fow8YCanRMeta?.alias || [],
    redirect: _91slug_936Fow8YCanRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___en-US",
    path: indexhSNZQk8tJEMeta?.path ?? "/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___ca",
    path: indexhSNZQk8tJEMeta?.path ?? "/ca/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___nl",
    path: indexhSNZQk8tJEMeta?.path ?? "/nl/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___uk",
    path: indexhSNZQk8tJEMeta?.path ?? "/uk/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___de",
    path: indexhSNZQk8tJEMeta?.path ?? "/de/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___mx",
    path: indexhSNZQk8tJEMeta?.path ?? "/mx/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___fr",
    path: indexhSNZQk8tJEMeta?.path ?? "/fr/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexhSNZQk8tJEMeta?.name ?? "company-careers-roles___fr-ca",
    path: indexhSNZQk8tJEMeta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexhSNZQk8tJEMeta || {},
    alias: indexhSNZQk8tJEMeta?.alias || [],
    redirect: indexhSNZQk8tJEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___en-US",
    path: diversity9ood82yHk4Meta?.path ?? "/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___ca",
    path: diversity9ood82yHk4Meta?.path ?? "/ca/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___nl",
    path: diversity9ood82yHk4Meta?.path ?? "/nl/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___uk",
    path: diversity9ood82yHk4Meta?.path ?? "/uk/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___de",
    path: diversity9ood82yHk4Meta?.path ?? "/de/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___mx",
    path: diversity9ood82yHk4Meta?.path ?? "/mx/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___fr",
    path: diversity9ood82yHk4Meta?.path ?? "/fr/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversity9ood82yHk4Meta?.name ?? "company-diversity___fr-ca",
    path: diversity9ood82yHk4Meta?.path ?? "/fr-ca/company/diversity",
    meta: diversity9ood82yHk4Meta || {},
    alias: diversity9ood82yHk4Meta?.alias || [],
    redirect: diversity9ood82yHk4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___en-US",
    path: index4N4KUKaIk6Meta?.path ?? "/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___ca",
    path: index4N4KUKaIk6Meta?.path ?? "/ca/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___nl",
    path: index4N4KUKaIk6Meta?.path ?? "/nl/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___uk",
    path: index4N4KUKaIk6Meta?.path ?? "/uk/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___de",
    path: index4N4KUKaIk6Meta?.path ?? "/de/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___mx",
    path: index4N4KUKaIk6Meta?.path ?? "/mx/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___fr",
    path: index4N4KUKaIk6Meta?.path ?? "/fr/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index4N4KUKaIk6Meta?.name ?? "company___fr-ca",
    path: index4N4KUKaIk6Meta?.path ?? "/fr-ca/company",
    meta: index4N4KUKaIk6Meta || {},
    alias: index4N4KUKaIk6Meta?.alias || [],
    redirect: index4N4KUKaIk6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencySVNPPhVB2aMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencySVNPPhVB2aMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencySVNPPhVB2aMeta || {},
    alias: efficiencySVNPPhVB2aMeta?.alias || [],
    redirect: efficiencySVNPPhVB2aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___en-US",
    path: safety1Tj0T9DXmGMeta?.path ?? "/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___ca",
    path: safety1Tj0T9DXmGMeta?.path ?? "/ca/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___nl",
    path: safety1Tj0T9DXmGMeta?.path ?? "/nl/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___uk",
    path: safety1Tj0T9DXmGMeta?.path ?? "/uk/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___de",
    path: safety1Tj0T9DXmGMeta?.path ?? "/de/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___mx",
    path: safety1Tj0T9DXmGMeta?.path ?? "/mx/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___fr",
    path: safety1Tj0T9DXmGMeta?.path ?? "/fr/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety1Tj0T9DXmGMeta?.name ?? "company-mission-safety___fr-ca",
    path: safety1Tj0T9DXmGMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safety1Tj0T9DXmGMeta || {},
    alias: safety1Tj0T9DXmGMeta?.alias || [],
    redirect: safety1Tj0T9DXmGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityGTnRTCORVPMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilityGTnRTCORVPMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilityGTnRTCORVPMeta || {},
    alias: sustainabilityGTnRTCORVPMeta?.alias || [],
    redirect: sustainabilityGTnRTCORVPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45news4yTFEJXgUrMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45news4yTFEJXgUrMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45news4yTFEJXgUrMeta || {},
    alias: in_45the_45news4yTFEJXgUrMeta?.alias || [],
    redirect: in_45the_45news4yTFEJXgUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: index9BN4z5T1cDMeta?.name ?? "company-news___en-US",
    path: index9BN4z5T1cDMeta?.path ?? "/company/news",
    meta: index9BN4z5T1cDMeta || {},
    alias: index9BN4z5T1cDMeta?.alias || [],
    redirect: index9BN4z5T1cDMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index9BN4z5T1cDMeta?.name ?? "company-news___ca",
    path: index9BN4z5T1cDMeta?.path ?? "/ca/company/news",
    meta: index9BN4z5T1cDMeta || {},
    alias: index9BN4z5T1cDMeta?.alias || [],
    redirect: index9BN4z5T1cDMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index9BN4z5T1cDMeta?.name ?? "company-news___mx",
    path: index9BN4z5T1cDMeta?.path ?? "/mx/company/news",
    meta: index9BN4z5T1cDMeta || {},
    alias: index9BN4z5T1cDMeta?.alias || [],
    redirect: index9BN4z5T1cDMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index9BN4z5T1cDMeta?.name ?? "company-news___uk",
    path: index9BN4z5T1cDMeta?.path ?? "/uk/company/news",
    meta: index9BN4z5T1cDMeta || {},
    alias: index9BN4z5T1cDMeta?.alias || [],
    redirect: index9BN4z5T1cDMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___de",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesAZcSpSRJUrMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesAZcSpSRJUrMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesAZcSpSRJUrMeta || {},
    alias: press_45releasesAZcSpSRJUrMeta?.alias || [],
    redirect: press_45releasesAZcSpSRJUrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___en-US",
    path: privacyi1URLlBCewMeta?.path ?? "/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___ca",
    path: privacyi1URLlBCewMeta?.path ?? "/ca/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___nl",
    path: privacyi1URLlBCewMeta?.path ?? "/nl/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___uk",
    path: privacyi1URLlBCewMeta?.path ?? "/uk/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___de",
    path: privacyi1URLlBCewMeta?.path ?? "/de/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___mx",
    path: privacyi1URLlBCewMeta?.path ?? "/mx/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___fr",
    path: privacyi1URLlBCewMeta?.path ?? "/fr/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyi1URLlBCewMeta?.name ?? "company-privacy___fr-ca",
    path: privacyi1URLlBCewMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacyi1URLlBCewMeta || {},
    alias: privacyi1URLlBCewMeta?.alias || [],
    redirect: privacyi1URLlBCewMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool5XDdeBbBCQMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45tool5XDdeBbBCQMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45tool5XDdeBbBCQMeta || {},
    alias: cost_45assessment_45tool5XDdeBbBCQMeta?.alias || [],
    redirect: cost_45assessment_45tool5XDdeBbBCQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___ca",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___nl",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___uk",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___de",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___mx",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___fr",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tp8cuvJyvOMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93tp8cuvJyvOMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93tp8cuvJyvOMeta || {},
    alias: _91slug_93tp8cuvJyvOMeta?.alias || [],
    redirect: _91slug_93tp8cuvJyvOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___en-US",
    path: indexXLKTSYtXXmMeta?.path ?? "/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___ca",
    path: indexXLKTSYtXXmMeta?.path ?? "/ca/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___nl",
    path: indexXLKTSYtXXmMeta?.path ?? "/nl/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___uk",
    path: indexXLKTSYtXXmMeta?.path ?? "/uk/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___de",
    path: indexXLKTSYtXXmMeta?.path ?? "/de/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___mx",
    path: indexXLKTSYtXXmMeta?.path ?? "/mx/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___fr",
    path: indexXLKTSYtXXmMeta?.path ?? "/fr/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLKTSYtXXmMeta?.name ?? "customers___fr-ca",
    path: indexXLKTSYtXXmMeta?.path ?? "/fr-ca/customers",
    meta: indexXLKTSYtXXmMeta || {},
    alias: indexXLKTSYtXXmMeta?.alias || [],
    redirect: indexXLKTSYtXXmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939gH7O1iBoPMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_939gH7O1iBoPMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_939gH7O1iBoPMeta || {},
    alias: _91slug_939gH7O1iBoPMeta?.alias || [],
    redirect: _91slug_939gH7O1iBoPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93txFTCLLPFhMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93txFTCLLPFhMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93txFTCLLPFhMeta || {},
    alias: _91slug_93txFTCLLPFhMeta?.alias || [],
    redirect: _91slug_93txFTCLLPFhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___en-US",
    path: indexDzigMZkXYkMeta?.path ?? "/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___ca",
    path: indexDzigMZkXYkMeta?.path ?? "/ca/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___nl",
    path: indexDzigMZkXYkMeta?.path ?? "/nl/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___uk",
    path: indexDzigMZkXYkMeta?.path ?? "/uk/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___de",
    path: indexDzigMZkXYkMeta?.path ?? "/de/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___mx",
    path: indexDzigMZkXYkMeta?.path ?? "/mx/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___fr",
    path: indexDzigMZkXYkMeta?.path ?? "/fr/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexDzigMZkXYkMeta?.name ?? "customers-tips___fr-ca",
    path: indexDzigMZkXYkMeta?.path ?? "/fr-ca/customers/tips",
    meta: indexDzigMZkXYkMeta || {},
    alias: indexDzigMZkXYkMeta?.alias || [],
    redirect: indexDzigMZkXYkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IIetYuF27kMeta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93IIetYuF27kMeta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93IIetYuF27kMeta || {},
    alias: _91_46_46_46slug_93IIetYuF27kMeta?.alias || [],
    redirect: _91_46_46_46slug_93IIetYuF27kMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondtCY2EYY3bGMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondtCY2EYY3bGMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondtCY2EYY3bGMeta || {},
    alias: above_45beyondtCY2EYY3bGMeta?.alias || [],
    redirect: above_45beyondtCY2EYY3bGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancetdPuIfBxn2Meta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performancetdPuIfBxn2Meta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performancetdPuIfBxn2Meta || {},
    alias: excellence_45performancetdPuIfBxn2Meta?.alias || [],
    redirect: excellence_45performancetdPuIfBxn2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceTSCmlt96FBMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45serviceTSCmlt96FBMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceTSCmlt96FBMeta || {},
    alias: excellence_45serviceTSCmlt96FBMeta?.alias || [],
    redirect: excellence_45serviceTSCmlt96FBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___en-US",
    path: indexPvSucrEbbrMeta?.path ?? "/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___ca",
    path: indexPvSucrEbbrMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___nl",
    path: indexPvSucrEbbrMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___uk",
    path: indexPvSucrEbbrMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___de",
    path: indexPvSucrEbbrMeta?.path ?? "/de/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___mx",
    path: indexPvSucrEbbrMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___fr",
    path: indexPvSucrEbbrMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexPvSucrEbbrMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexPvSucrEbbrMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexPvSucrEbbrMeta || {},
    alias: indexPvSucrEbbrMeta?.alias || [],
    redirect: indexPvSucrEbbrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetjeE6lH8um6Meta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetjeE6lH8um6Meta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetjeE6lH8um6Meta || {},
    alias: safest_45fleetjeE6lH8um6Meta?.alias || [],
    redirect: safest_45fleetjeE6lH8um6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceUeHXSen2o5Meta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceUeHXSen2o5Meta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceUeHXSen2o5Meta || {},
    alias: driver_45performanceUeHXSen2o5Meta?.alias || [],
    redirect: driver_45performanceUeHXSen2o5Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2gL99wJY48Meta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performance2gL99wJY48Meta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performance2gL99wJY48Meta || {},
    alias: excellence_45performance2gL99wJY48Meta?.alias || [],
    redirect: excellence_45performance2gL99wJY48Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator36cxWyhPLoMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovator36cxWyhPLoMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator36cxWyhPLoMeta || {},
    alias: fleet_45innovator36cxWyhPLoMeta?.alias || [],
    redirect: fleet_45innovator36cxWyhPLoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___en-US",
    path: indexxew4DhBtoNMeta?.path ?? "/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___ca",
    path: indexxew4DhBtoNMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___nl",
    path: indexxew4DhBtoNMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___uk",
    path: indexxew4DhBtoNMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___de",
    path: indexxew4DhBtoNMeta?.path ?? "/de/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___mx",
    path: indexxew4DhBtoNMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___fr",
    path: indexxew4DhBtoNMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexxew4DhBtoNMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexxew4DhBtoNMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexxew4DhBtoNMeta || {},
    alias: indexxew4DhBtoNMeta?.alias || [],
    redirect: indexxew4DhBtoNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetWGHiSU7GRBMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetWGHiSU7GRBMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetWGHiSU7GRBMeta || {},
    alias: public_45fleetWGHiSU7GRBMeta?.alias || [],
    redirect: public_45fleetWGHiSU7GRBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetAgmVS8ZGUsMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetAgmVS8ZGUsMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetAgmVS8ZGUsMeta || {},
    alias: safest_45fleetAgmVS8ZGUsMeta?.alias || [],
    redirect: safest_45fleetAgmVS8ZGUsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetAgmVS8ZGUsMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetAgmVS8ZGUsMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetAgmVS8ZGUsMeta || {},
    alias: safest_45fleetAgmVS8ZGUsMeta?.alias || [],
    redirect: safest_45fleetAgmVS8ZGUsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___en-US",
    path: index6bwMfVCiS3Meta?.path ?? "/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___ca",
    path: index6bwMfVCiS3Meta?.path ?? "/ca/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___nl",
    path: index6bwMfVCiS3Meta?.path ?? "/nl/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___uk",
    path: index6bwMfVCiS3Meta?.path ?? "/uk/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___de",
    path: index6bwMfVCiS3Meta?.path ?? "/de/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___mx",
    path: index6bwMfVCiS3Meta?.path ?? "/mx/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___fr",
    path: index6bwMfVCiS3Meta?.path ?? "/fr/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: index6bwMfVCiS3Meta?.name ?? "customers-topfleets___fr-ca",
    path: index6bwMfVCiS3Meta?.path ?? "/fr-ca/customers/topfleets",
    meta: index6bwMfVCiS3Meta || {},
    alias: index6bwMfVCiS3Meta?.alias || [],
    redirect: index6bwMfVCiS3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___en-US",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___ca",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/ca/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___nl",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/nl/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___uk",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/uk/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___de",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/de/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___mx",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/mx/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___fr",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/fr/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GEWVWrxKePMeta?.name ?? "demos-prefix-slug___fr-ca",
    path: _91slug_93GEWVWrxKePMeta?.path ?? "/fr-ca/demos/:prefix()/:slug()",
    meta: _91slug_93GEWVWrxKePMeta || {},
    alias: _91slug_93GEWVWrxKePMeta?.alias || [],
    redirect: _91slug_93GEWVWrxKePMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___en-US",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___ca",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/ca/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___nl",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/nl/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___uk",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/uk/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___de",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/de/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___mx",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/mx/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___fr",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/fr/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RjKnFZlWGCMeta?.name ?? "demos-slug___fr-ca",
    path: _91slug_93RjKnFZlWGCMeta?.path ?? "/fr-ca/demos/:slug()",
    meta: _91slug_93RjKnFZlWGCMeta || {},
    alias: _91slug_93RjKnFZlWGCMeta?.alias || [],
    redirect: _91slug_93RjKnFZlWGCMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/demos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___en-US",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___ca",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___nl",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___uk",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___de",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___mx",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___fr",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931NjcDKH3QtMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_931NjcDKH3QtMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_931NjcDKH3QtMeta || {},
    alias: _91slug_931NjcDKH3QtMeta?.alias || [],
    redirect: _91slug_931NjcDKH3QtMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___en-US",
    path: indexL5bz7JM2MJMeta?.path ?? "/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___ca",
    path: indexL5bz7JM2MJMeta?.path ?? "/ca/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___nl",
    path: indexL5bz7JM2MJMeta?.path ?? "/nl/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___uk",
    path: indexL5bz7JM2MJMeta?.path ?? "/uk/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___de",
    path: indexL5bz7JM2MJMeta?.path ?? "/de/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___mx",
    path: indexL5bz7JM2MJMeta?.path ?? "/mx/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___fr",
    path: indexL5bz7JM2MJMeta?.path ?? "/fr/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5bz7JM2MJMeta?.name ?? "events___fr-ca",
    path: indexL5bz7JM2MJMeta?.path ?? "/fr-ca/events",
    meta: indexL5bz7JM2MJMeta || {},
    alias: indexL5bz7JM2MJMeta?.alias || [],
    redirect: indexL5bz7JM2MJMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___en-US",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___ca",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___nl",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___uk",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___de",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/de/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___mx",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___fr",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appZ1NP45PLZVMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45appZ1NP45PLZVMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45appZ1NP45PLZVMeta || {},
    alias: fleet_45appZ1NP45PLZVMeta?.alias || [],
    redirect: fleet_45appZ1NP45PLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YJcdsOeI5aMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93YJcdsOeI5aMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93YJcdsOeI5aMeta || {},
    alias: _91slug_93YJcdsOeI5aMeta?.alias || [],
    redirect: _91slug_93YJcdsOeI5aMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___de",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93w4HzKR829bMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93w4HzKR829bMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93w4HzKR829bMeta || {},
    alias: _91slug_93w4HzKR829bMeta?.alias || [],
    redirect: _91slug_93w4HzKR829bMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938r23lxRqNkMeta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_938r23lxRqNkMeta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_938r23lxRqNkMeta || {},
    alias: _91slug_938r23lxRqNkMeta?.alias || [],
    redirect: _91slug_938r23lxRqNkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___en-US",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___ca",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___nl",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___uk",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___de",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___mx",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___fr",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yHeSGgQ0nAMeta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93yHeSGgQ0nAMeta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93yHeSGgQ0nAMeta || {},
    alias: _91slug_93yHeSGgQ0nAMeta?.alias || [],
    redirect: _91slug_93yHeSGgQ0nAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___en-US",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___ca",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___nl",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___uk",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___de",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/de/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___mx",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___fr",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933pGCIQicH2Meta?.name ?? "guides-slug___fr-ca",
    path: _91slug_933pGCIQicH2Meta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_933pGCIQicH2Meta || {},
    alias: _91slug_933pGCIQicH2Meta?.alias || [],
    redirect: _91slug_933pGCIQicH2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5zmXKi99utMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talent5zmXKi99utMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5zmXKi99utMeta || {},
    alias: attract_45retain_45talent5zmXKi99utMeta?.alias || [],
    redirect: attract_45retain_45talent5zmXKi99utMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta || {},
    alias: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsBv2LQqQ5Z0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexY50pqSdSHzMeta?.path ?? "/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexY50pqSdSHzMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexY50pqSdSHzMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexY50pqSdSHzMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___de",
    path: indexY50pqSdSHzMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexY50pqSdSHzMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexY50pqSdSHzMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexY50pqSdSHzMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexY50pqSdSHzMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexY50pqSdSHzMeta || {},
    alias: indexY50pqSdSHzMeta?.alias || [],
    redirect: indexY50pqSdSHzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsQrdYNwAw4oMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetsQrdYNwAw4oMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsQrdYNwAw4oMeta || {},
    alias: protect_45optimize_45assetsQrdYNwAw4oMeta?.alias || [],
    redirect: protect_45optimize_45assetsQrdYNwAw4oMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsLRqdbM8aPhMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsLRqdbM8aPhMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsLRqdbM8aPhMeta || {},
    alias: best_45dash_45camsLRqdbM8aPhMeta?.alias || [],
    redirect: best_45dash_45camsLRqdbM8aPhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexNV1f3CbYMNMeta?.path ?? "/guides/fleet-safety",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___ca",
    path: indexNV1f3CbYMNMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___nl",
    path: indexNV1f3CbYMNMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___uk",
    path: indexNV1f3CbYMNMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___de",
    path: indexNV1f3CbYMNMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___mx",
    path: indexNV1f3CbYMNMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___fr",
    path: indexNV1f3CbYMNMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexNV1f3CbYMNMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexNV1f3CbYMNMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexNV1f3CbYMNMeta || {},
    alias: indexNV1f3CbYMNMeta?.alias || [],
    redirect: indexNV1f3CbYMNMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programkJDhNzLE4zMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programkJDhNzLE4zMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programkJDhNzLE4zMeta || {},
    alias: safety_45programkJDhNzLE4zMeta?.alias || [],
    redirect: safety_45programkJDhNzLE4zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiiFSWpD2xqlMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roiiFSWpD2xqlMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiiFSWpD2xqlMeta || {},
    alias: safety_45roiiFSWpD2xqlMeta?.alias || [],
    redirect: safety_45roiiFSWpD2xqlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___en-US",
    path: index2tl8IWx6TsMeta?.path ?? "/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___ca",
    path: index2tl8IWx6TsMeta?.path ?? "/ca/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___nl",
    path: index2tl8IWx6TsMeta?.path ?? "/nl/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___uk",
    path: index2tl8IWx6TsMeta?.path ?? "/uk/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___de",
    path: index2tl8IWx6TsMeta?.path ?? "/de/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___mx",
    path: index2tl8IWx6TsMeta?.path ?? "/mx/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___fr",
    path: index2tl8IWx6TsMeta?.path ?? "/fr/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: index2tl8IWx6TsMeta?.name ?? "guides___fr-ca",
    path: index2tl8IWx6TsMeta?.path ?? "/fr-ca/guides",
    meta: index2tl8IWx6TsMeta || {},
    alias: index2tl8IWx6TsMeta?.alias || [],
    redirect: index2tl8IWx6TsMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45future7GDQ2ZYQlUMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45future7GDQ2ZYQlUMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45future7GDQ2ZYQlUMeta || {},
    alias: build_45for_45the_45future7GDQ2ZYQlUMeta?.alias || [],
    redirect: build_45for_45the_45future7GDQ2ZYQlUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamssOWsrnXSiMMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamssOWsrnXSiMMeta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamssOWsrnXSiMMeta || {},
    alias: empower_45your_45teamssOWsrnXSiMMeta?.alias || [],
    redirect: empower_45your_45teamssOWsrnXSiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexmBryCdRRNQMeta?.path ?? "/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___ca",
    path: indexmBryCdRRNQMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___nl",
    path: indexmBryCdRRNQMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___uk",
    path: indexmBryCdRRNQMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___de",
    path: indexmBryCdRRNQMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___mx",
    path: indexmBryCdRRNQMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___fr",
    path: indexmBryCdRRNQMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBryCdRRNQMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexmBryCdRRNQMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexmBryCdRRNQMeta || {},
    alias: indexmBryCdRRNQMeta?.alias || [],
    redirect: indexmBryCdRRNQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45data0xC0WHSEKnMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45data0xC0WHSEKnMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45data0xC0WHSEKnMeta || {},
    alias: take_45action_45with_45data0xC0WHSEKnMeta?.alias || [],
    redirect: take_45action_45with_45data0xC0WHSEKnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P7ifQ3uj5yMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93P7ifQ3uj5yMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93P7ifQ3uj5yMeta || {},
    alias: _91slug_93P7ifQ3uj5yMeta?.alias || [],
    redirect: _91slug_93P7ifQ3uj5yMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indexb6CFjE0o8wMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indexb6CFjE0o8wMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indexb6CFjE0o8wMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indexb6CFjE0o8wMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indexb6CFjE0o8wMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indexb6CFjE0o8wMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indexb6CFjE0o8wMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexb6CFjE0o8wMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indexb6CFjE0o8wMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indexb6CFjE0o8wMeta || {},
    alias: indexb6CFjE0o8wMeta?.alias || [],
    redirect: indexb6CFjE0o8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___en-US",
    path: indexNsfnByPigFMeta?.path ?? "/",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___ca",
    path: indexNsfnByPigFMeta?.path ?? "/ca",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___nl",
    path: indexNsfnByPigFMeta?.path ?? "/nl",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___uk",
    path: indexNsfnByPigFMeta?.path ?? "/uk",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___de",
    path: indexNsfnByPigFMeta?.path ?? "/de",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___mx",
    path: indexNsfnByPigFMeta?.path ?? "/mx",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___fr",
    path: indexNsfnByPigFMeta?.path ?? "/fr",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNsfnByPigFMeta?.name ?? "index___fr-ca",
    path: indexNsfnByPigFMeta?.path ?? "/fr-ca",
    meta: indexNsfnByPigFMeta || {},
    alias: indexNsfnByPigFMeta?.alias || [],
    redirect: indexNsfnByPigFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___ca",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___nl",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___uk",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___de",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___mx",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___fr",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935Cr8NjqMcoMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_935Cr8NjqMcoMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_935Cr8NjqMcoMeta || {},
    alias: _91slug_935Cr8NjqMcoMeta?.alias || [],
    redirect: _91slug_935Cr8NjqMcoMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___ca",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___nl",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___uk",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___de",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___mx",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___fr",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MY2aqomYpzMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93MY2aqomYpzMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93MY2aqomYpzMeta || {},
    alias: _91slug_93MY2aqomYpzMeta?.alias || [],
    redirect: _91slug_93MY2aqomYpzMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___en-US",
    path: indexkKGTW20LYhMeta?.path ?? "/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___ca",
    path: indexkKGTW20LYhMeta?.path ?? "/ca/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___nl",
    path: indexkKGTW20LYhMeta?.path ?? "/nl/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___uk",
    path: indexkKGTW20LYhMeta?.path ?? "/uk/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___de",
    path: indexkKGTW20LYhMeta?.path ?? "/de/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___mx",
    path: indexkKGTW20LYhMeta?.path ?? "/mx/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___fr",
    path: indexkKGTW20LYhMeta?.path ?? "/fr/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexkKGTW20LYhMeta?.name ?? "industries___fr-ca",
    path: indexkKGTW20LYhMeta?.path ?? "/fr-ca/industries",
    meta: indexkKGTW20LYhMeta || {},
    alias: indexkKGTW20LYhMeta?.alias || [],
    redirect: indexkKGTW20LYhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___en-US",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___ca",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/ca/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___nl",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/nl/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___uk",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/uk/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___de",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/de/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___mx",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/mx/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___fr",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/fr/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesQVqMEJ7UWlMeta?.name ?? "insights-cities___fr-ca",
    path: citiesQVqMEJ7UWlMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiesQVqMEJ7UWlMeta || {},
    alias: citiesQVqMEJ7UWlMeta?.alias || [],
    redirect: citiesQVqMEJ7UWlMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___en-US",
    path: indexvvM5FLv7E3Meta?.path ?? "/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___ca",
    path: indexvvM5FLv7E3Meta?.path ?? "/ca/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___nl",
    path: indexvvM5FLv7E3Meta?.path ?? "/nl/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___uk",
    path: indexvvM5FLv7E3Meta?.path ?? "/uk/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___de",
    path: indexvvM5FLv7E3Meta?.path ?? "/de/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___mx",
    path: indexvvM5FLv7E3Meta?.path ?? "/mx/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___fr",
    path: indexvvM5FLv7E3Meta?.path ?? "/fr/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexvvM5FLv7E3Meta?.name ?? "integrations___fr-ca",
    path: indexvvM5FLv7E3Meta?.path ?? "/fr-ca/integrations",
    meta: indexvvM5FLv7E3Meta || {},
    alias: indexvvM5FLv7E3Meta?.alias || [],
    redirect: indexvvM5FLv7E3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___en-US",
    path: indexbaat0epN5gMeta?.path ?? "/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___ca",
    path: indexbaat0epN5gMeta?.path ?? "/ca/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___nl",
    path: indexbaat0epN5gMeta?.path ?? "/nl/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___uk",
    path: indexbaat0epN5gMeta?.path ?? "/uk/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___de",
    path: indexbaat0epN5gMeta?.path ?? "/de/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___mx",
    path: indexbaat0epN5gMeta?.path ?? "/mx/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___fr",
    path: indexbaat0epN5gMeta?.path ?? "/fr/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexbaat0epN5gMeta?.name ?? "invite___fr-ca",
    path: indexbaat0epN5gMeta?.path ?? "/fr-ca/invite",
    meta: indexbaat0epN5gMeta || {},
    alias: indexbaat0epN5gMeta?.alias || [],
    redirect: indexbaat0epN5gMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___en-US",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___ca",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/ca/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___nl",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/nl/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___uk",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/uk/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___de",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/de/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___mx",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/mx/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___fr",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/fr/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A5H5axKDb4Meta?.name ?? "legal-slug___fr-ca",
    path: _91slug_93A5H5axKDb4Meta?.path ?? "/fr-ca/legal/:slug()",
    meta: _91slug_93A5H5axKDb4Meta || {},
    alias: _91slug_93A5H5axKDb4Meta?.alias || [],
    redirect: _91slug_93A5H5axKDb4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/[slug].vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticec3LtBX6r4CMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticec3LtBX6r4CMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticec3LtBX6r4CMeta || {},
    alias: candidate_45privacy_45noticec3LtBX6r4CMeta?.alias || [],
    redirect: candidate_45privacy_45noticec3LtBX6r4CMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentUCU2vWELnjMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentUCU2vWELnjMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentUCU2vWELnjMeta || {},
    alias: cookie_45consentUCU2vWELnjMeta?.alias || [],
    redirect: cookie_45consentUCU2vWELnjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyCsQgWEFtT8Meta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyCsQgWEFtT8Meta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyCsQgWEFtT8Meta || {},
    alias: cookie_45policyCsQgWEFtT8Meta?.alias || [],
    redirect: cookie_45policyCsQgWEFtT8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBKWRzXvBAFMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumBKWRzXvBAFMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumBKWRzXvBAFMeta || {},
    alias: data_45protection_45addendumBKWRzXvBAFMeta?.alias || [],
    redirect: data_45protection_45addendumBKWRzXvBAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termshGY1Mq4D2zMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termshGY1Mq4D2zMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termshGY1Mq4D2zMeta || {},
    alias: express_45order_45termshGY1Mq4D2zMeta?.alias || [],
    redirect: express_45order_45termshGY1Mq4D2zMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warranty58TxA39hxPMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warranty58TxA39hxPMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warranty58TxA39hxPMeta || {},
    alias: hardware_45warranty58TxA39hxPMeta?.alias || [],
    redirect: hardware_45warranty58TxA39hxPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3w02h0BuQZMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45sla3w02h0BuQZMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla3w02h0BuQZMeta || {},
    alias: hosted_45software_45sla3w02h0BuQZMeta?.alias || [],
    redirect: hosted_45software_45sla3w02h0BuQZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductxUkyoF8IokMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductxUkyoF8IokMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductxUkyoF8IokMeta || {},
    alias: partner_45code_45of_45conductxUkyoF8IokMeta?.alias || [],
    redirect: partner_45code_45of_45conductxUkyoF8IokMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceumFtPlxfkvMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45serviceumFtPlxfkvMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceumFtPlxfkvMeta || {},
    alias: platform_45terms_45of_45serviceumFtPlxfkvMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceumFtPlxfkvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___en-US",
    path: privacyeN77NXFxKLMeta?.path ?? "/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___ca",
    path: privacyeN77NXFxKLMeta?.path ?? "/ca/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___nl",
    path: privacyeN77NXFxKLMeta?.path ?? "/nl/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___uk",
    path: privacyeN77NXFxKLMeta?.path ?? "/uk/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___de",
    path: privacyeN77NXFxKLMeta?.path ?? "/de/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___mx",
    path: privacyeN77NXFxKLMeta?.path ?? "/mx/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___fr",
    path: privacyeN77NXFxKLMeta?.path ?? "/fr/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyeN77NXFxKLMeta?.name ?? "legal-privacy___fr-ca",
    path: privacyeN77NXFxKLMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyeN77NXFxKLMeta || {},
    alias: privacyeN77NXFxKLMeta?.alias || [],
    redirect: privacyeN77NXFxKLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceXYoq8pc0GFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataBQFpLZhMm3Meta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdataBQFpLZhMm3Meta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataBQFpLZhMm3Meta || {},
    alias: requestingcustomerdataBQFpLZhMm3Meta?.alias || [],
    redirect: requestingcustomerdataBQFpLZhMm3Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___en-US",
    path: security02CKStO1hRMeta?.path ?? "/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___ca",
    path: security02CKStO1hRMeta?.path ?? "/ca/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___nl",
    path: security02CKStO1hRMeta?.path ?? "/nl/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___uk",
    path: security02CKStO1hRMeta?.path ?? "/uk/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___de",
    path: security02CKStO1hRMeta?.path ?? "/de/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___mx",
    path: security02CKStO1hRMeta?.path ?? "/mx/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___fr",
    path: security02CKStO1hRMeta?.path ?? "/fr/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: security02CKStO1hRMeta?.name ?? "legal-security___fr-ca",
    path: security02CKStO1hRMeta?.path ?? "/fr-ca/legal/security",
    meta: security02CKStO1hRMeta || {},
    alias: security02CKStO1hRMeta?.alias || [],
    redirect: security02CKStO1hRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportfkthYh3BPXMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreportfkthYh3BPXMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreportfkthYh3BPXMeta || {},
    alias: transparencyreportfkthYh3BPXMeta?.alias || [],
    redirect: transparencyreportfkthYh3BPXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceql0NTjn89BMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45serviceql0NTjn89BMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceql0NTjn89BMeta || {},
    alias: website_45terms_45of_45serviceql0NTjn89BMeta?.alias || [],
    redirect: website_45terms_45of_45serviceql0NTjn89BMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesRBRkuiZJbfMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesRBRkuiZJbfMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesRBRkuiZJbfMeta || {},
    alias: your_45privacy_45choicesRBRkuiZJbfMeta?.alias || [],
    redirect: your_45privacy_45choicesRBRkuiZJbfMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___en-US",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___ca",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/ca/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___nl",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/nl/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___uk",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/uk/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___de",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/de/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___mx",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/mx/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___fr",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/fr/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: operatesmarterDAddnge9LPMeta?.name ?? "operatesmarter___fr-ca",
    path: operatesmarterDAddnge9LPMeta?.path ?? "/fr-ca/operatesmarter",
    meta: operatesmarterDAddnge9LPMeta || {},
    alias: operatesmarterDAddnge9LPMeta?.alias || [],
    redirect: operatesmarterDAddnge9LPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/operatesmarter.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___en-US",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___ca",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/ca/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___nl",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/nl/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___uk",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/uk/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___de",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/de/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___mx",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/mx/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___fr",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/fr/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingqCCVWG7Y8wMeta?.name ?? "page-pricing___fr-ca",
    path: pricingqCCVWG7Y8wMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingqCCVWG7Y8wMeta || {},
    alias: pricingqCCVWG7Y8wMeta?.alias || [],
    redirect: pricingqCCVWG7Y8wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kEahpPnyGPMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93kEahpPnyGPMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93kEahpPnyGPMeta || {},
    alias: _91slug_93kEahpPnyGPMeta?.alias || [],
    redirect: _91slug_93kEahpPnyGPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___en-US",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___ca",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___nl",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___uk",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___de",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___mx",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___fr",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H0c7ROUyyOMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93H0c7ROUyyOMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93H0c7ROUyyOMeta || {},
    alias: _91slug_93H0c7ROUyyOMeta?.alias || [],
    redirect: _91slug_93H0c7ROUyyOMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingrNqSKL8LdBMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingrNqSKL8LdBMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingrNqSKL8LdBMeta || {},
    alias: asset_45trackingrNqSKL8LdBMeta?.alias || [],
    redirect: asset_45trackingrNqSKL8LdBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsj0LMCtEXU2Meta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsj0LMCtEXU2Meta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsj0LMCtEXU2Meta || {},
    alias: best_45dash_45camsj0LMCtEXU2Meta?.alias || [],
    redirect: best_45dash_45camsj0LMCtEXU2Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___en-US",
    path: constructionsvf23pdQHPMeta?.path ?? "/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___ca",
    path: constructionsvf23pdQHPMeta?.path ?? "/ca/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___nl",
    path: constructionsvf23pdQHPMeta?.path ?? "/nl/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___uk",
    path: constructionsvf23pdQHPMeta?.path ?? "/uk/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___de",
    path: constructionsvf23pdQHPMeta?.path ?? "/de/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___mx",
    path: constructionsvf23pdQHPMeta?.path ?? "/mx/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___fr",
    path: constructionsvf23pdQHPMeta?.path ?? "/fr/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionsvf23pdQHPMeta?.name ?? "pages-construction___fr-ca",
    path: constructionsvf23pdQHPMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionsvf23pdQHPMeta || {},
    alias: constructionsvf23pdQHPMeta?.alias || [],
    redirect: constructionsvf23pdQHPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementg7CnttcrYEMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementg7CnttcrYEMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementg7CnttcrYEMeta || {},
    alias: fleet_45managementg7CnttcrYEMeta?.alias || [],
    redirect: fleet_45managementg7CnttcrYEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___en-US",
    path: nationwideyYL1pRonX1Meta?.path ?? "/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___ca",
    path: nationwideyYL1pRonX1Meta?.path ?? "/ca/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___nl",
    path: nationwideyYL1pRonX1Meta?.path ?? "/nl/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___uk",
    path: nationwideyYL1pRonX1Meta?.path ?? "/uk/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___de",
    path: nationwideyYL1pRonX1Meta?.path ?? "/de/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___mx",
    path: nationwideyYL1pRonX1Meta?.path ?? "/mx/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___fr",
    path: nationwideyYL1pRonX1Meta?.path ?? "/fr/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideyYL1pRonX1Meta?.name ?? "pages-nationwide___fr-ca",
    path: nationwideyYL1pRonX1Meta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwideyYL1pRonX1Meta || {},
    alias: nationwideyYL1pRonX1Meta?.alias || [],
    redirect: nationwideyYL1pRonX1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPq4xaT2uusMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingPq4xaT2uusMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingPq4xaT2uusMeta || {},
    alias: safety_45coachingPq4xaT2uusMeta?.alias || [],
    redirect: safety_45coachingPq4xaT2uusMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___en-US",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___ca",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/ca/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___nl",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/nl/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___uk",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/uk/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___de",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/de/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___mx",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/mx/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___fr",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/fr/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93VALZKcIQXdMeta?.name ?? "pdf-track-slug___fr-ca",
    path: _91_46_46_46slug_93VALZKcIQXdMeta?.path ?? "/fr-ca/pdf/track/:slug(.*)*",
    meta: _91_46_46_46slug_93VALZKcIQXdMeta || {},
    alias: _91_46_46_46slug_93VALZKcIQXdMeta?.alias || [],
    redirect: _91_46_46_46slug_93VALZKcIQXdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pdf/track/[...slug].vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___en-US",
    path: pricingWcsctnowSkMeta?.path ?? "/pricing",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___ca",
    path: pricingWcsctnowSkMeta?.path ?? "/ca/pricing",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___nl",
    path: pricingWcsctnowSkMeta?.path ?? "/nl/pricing",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___uk",
    path: pricingWcsctnowSkMeta?.path ?? "/uk/pricing",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___de",
    path: pricingWcsctnowSkMeta?.path ?? "/de/pricing",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___mx",
    path: pricingWcsctnowSkMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___fr",
    path: pricingWcsctnowSkMeta?.path ?? "/fr/pricing",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingWcsctnowSkMeta?.name ?? "pricing___fr-ca",
    path: pricingWcsctnowSkMeta?.path ?? "/fr-ca/pricing",
    meta: pricingWcsctnowSkMeta || {},
    alias: pricingWcsctnowSkMeta?.alias || [],
    redirect: pricingWcsctnowSkMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zs47GbDHdcMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93zs47GbDHdcMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93zs47GbDHdcMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93zs47GbDHdcMeta?.alias || [],
    redirect: _91slug_93zs47GbDHdcMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___en-US",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___ca",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/ca/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___nl",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/nl/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___uk",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/uk/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___de",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/de/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___mx",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/mx/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___fr",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/fr/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dg2SPqg8p4Meta?.name ?? "products-slug___fr-ca",
    path: _91slug_93Dg2SPqg8p4Meta?.path ?? "/fr-ca/products/:slug()",
    meta: { ...(_91slug_93Dg2SPqg8p4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Dg2SPqg8p4Meta?.alias || [],
    redirect: _91slug_93Dg2SPqg8p4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingpu2ue9sVq1Meta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingpu2ue9sVq1Meta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingpu2ue9sVq1Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingpu2ue9sVq1Meta?.alias || [],
    redirect: asset_45trackingpu2ue9sVq1Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinghfLu5EZ21wMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackinghfLu5EZ21wMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinghfLu5EZ21wMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinghfLu5EZ21wMeta?.alias || [],
    redirect: equipment_45trackinghfLu5EZ21wMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringo0N3UzHUWIMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringo0N3UzHUWIMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringo0N3UzHUWIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringo0N3UzHUWIMeta?.alias || [],
    redirect: reefer_45monitoringo0N3UzHUWIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersHPdjxki0YRMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailersHPdjxki0YRMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersHPdjxki0YRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersHPdjxki0YRMeta?.alias || [],
    redirect: smart_45trailersHPdjxki0YRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingzoDV8aeINiMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingzoDV8aeINiMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingzoDV8aeINiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingzoDV8aeINiMeta?.alias || [],
    redirect: trailer_45trackingzoDV8aeINiMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___en-US",
    path: indexB6vdrIYYnRMeta?.path ?? "/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___ca",
    path: indexB6vdrIYYnRMeta?.path ?? "/ca/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___nl",
    path: indexB6vdrIYYnRMeta?.path ?? "/nl/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___uk",
    path: indexB6vdrIYYnRMeta?.path ?? "/uk/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___de",
    path: indexB6vdrIYYnRMeta?.path ?? "/de/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___mx",
    path: indexB6vdrIYYnRMeta?.path ?? "/mx/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___fr",
    path: indexB6vdrIYYnRMeta?.path ?? "/fr/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexB6vdrIYYnRMeta?.name ?? "products___fr-ca",
    path: indexB6vdrIYYnRMeta?.path ?? "/fr-ca/products",
    meta: { ...(indexB6vdrIYYnRMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexB6vdrIYYnRMeta?.alias || [],
    redirect: indexB6vdrIYYnRMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___en-US",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___ca",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/ca/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___nl",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/nl/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___uk",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/uk/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___de",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/de/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___mx",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/mx/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___fr",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/fr/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P2MxtAbrZ8Meta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93P2MxtAbrZ8Meta?.path ?? "/fr-ca/products/models/:slug()",
    meta: { ...(_91slug_93P2MxtAbrZ8Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93P2MxtAbrZ8Meta?.alias || [],
    redirect: _91slug_93P2MxtAbrZ8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___en-US",
    path: index0AdVMyDVTrMeta?.path ?? "/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___ca",
    path: index0AdVMyDVTrMeta?.path ?? "/ca/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___nl",
    path: index0AdVMyDVTrMeta?.path ?? "/nl/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___uk",
    path: index0AdVMyDVTrMeta?.path ?? "/uk/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___de",
    path: index0AdVMyDVTrMeta?.path ?? "/de/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___mx",
    path: index0AdVMyDVTrMeta?.path ?? "/mx/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___fr",
    path: index0AdVMyDVTrMeta?.path ?? "/fr/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: index0AdVMyDVTrMeta?.name ?? "products-models___fr-ca",
    path: index0AdVMyDVTrMeta?.path ?? "/fr-ca/products/models",
    meta: { ...(index0AdVMyDVTrMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: index0AdVMyDVTrMeta?.alias || [],
    redirect: index0AdVMyDVTrMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingSa2wetbIloMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingSa2wetbIloMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingSa2wetbIloMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingSa2wetbIloMeta?.alias || [],
    redirect: gps_45fleet_45trackingSa2wetbIloMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___en-US",
    path: indexpRJdgHWVDbMeta?.path ?? "/products/telematics",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___ca",
    path: indexpRJdgHWVDbMeta?.path ?? "/ca/products/telematics",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___nl",
    path: indexpRJdgHWVDbMeta?.path ?? "/nl/products/telematics",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___uk",
    path: indexpRJdgHWVDbMeta?.path ?? "/uk/products/telematics",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___de",
    path: indexpRJdgHWVDbMeta?.path ?? "/de/products/telematics",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___mx",
    path: indexpRJdgHWVDbMeta?.path ?? "/mx/products/flota",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___fr",
    path: indexpRJdgHWVDbMeta?.path ?? "/fr/products/telematics",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexpRJdgHWVDbMeta?.name ?? "products-telematics___fr-ca",
    path: indexpRJdgHWVDbMeta?.path ?? "/fr-ca/products/telematics",
    meta: { ...(indexpRJdgHWVDbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpRJdgHWVDbMeta?.alias || [],
    redirect: indexpRJdgHWVDbMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: racingpuNhpUX7fQMeta?.name ?? "racing___en-US",
    path: racingpuNhpUX7fQMeta?.path ?? "/racing",
    meta: racingpuNhpUX7fQMeta || {},
    alias: racingpuNhpUX7fQMeta?.alias || [],
    redirect: racingpuNhpUX7fQMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/racing.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___en-US",
    path: referralshz8bceV91ZMeta?.path ?? "/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___ca",
    path: referralshz8bceV91ZMeta?.path ?? "/ca/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___nl",
    path: referralshz8bceV91ZMeta?.path ?? "/nl/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___uk",
    path: referralshz8bceV91ZMeta?.path ?? "/uk/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___de",
    path: referralshz8bceV91ZMeta?.path ?? "/de/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___mx",
    path: referralshz8bceV91ZMeta?.path ?? "/mx/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___fr",
    path: referralshz8bceV91ZMeta?.path ?? "/fr/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralshz8bceV91ZMeta?.name ?? "referrals___fr-ca",
    path: referralshz8bceV91ZMeta?.path ?? "/fr-ca/referrals",
    meta: referralshz8bceV91ZMeta || {},
    alias: referralshz8bceV91ZMeta?.alias || [],
    redirect: referralshz8bceV91ZMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___en-US",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___ca",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/ca/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___nl",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/nl/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___uk",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/uk/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___de",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/de/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___mx",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/mx/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___fr",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/fr/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oqSQN4OztEMeta?.name ?? "resources-prefix-slug___fr-ca",
    path: _91slug_93oqSQN4OztEMeta?.path ?? "/fr-ca/resources/:prefix()/:slug()",
    meta: _91slug_93oqSQN4OztEMeta || {},
    alias: _91slug_93oqSQN4OztEMeta?.alias || [],
    redirect: _91slug_93oqSQN4OztEMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___en-US",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___ca",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___nl",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___uk",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___de",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___mx",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___fr",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bTuPEQSTsjMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93bTuPEQSTsjMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93bTuPEQSTsjMeta || {},
    alias: _91slug_93bTuPEQSTsjMeta?.alias || [],
    redirect: _91slug_93bTuPEQSTsjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___ca",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___nl",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___uk",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___de",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___mx",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___fr",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsVVcRSMfTGmMeta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assetsVVcRSMfTGmMeta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assetsVVcRSMfTGmMeta || {},
    alias: brand_45assetsVVcRSMfTGmMeta?.alias || [],
    redirect: brand_45assetsVVcRSMfTGmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pOd4jOG7DAMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93pOd4jOG7DAMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93pOd4jOG7DAMeta || {},
    alias: _91slug_93pOd4jOG7DAMeta?.alias || [],
    redirect: _91slug_93pOd4jOG7DAMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerYRJVpT29oUMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centerYRJVpT29oUMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerYRJVpT29oUMeta || {},
    alias: email_45preferences_45centerYRJVpT29oUMeta?.alias || [],
    redirect: email_45preferences_45centerYRJVpT29oUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___en-US",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___ca",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___nl",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/nl/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___uk",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/uk/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___de",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/de/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___mx",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/mx/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___fr",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/fr/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeUgT01mqYI0Meta?.name ?? "resources-content-one-click-unsubscribe___fr-ca",
    path: one_45click_45unsubscribeUgT01mqYI0Meta?.path ?? "/fr-ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeUgT01mqYI0Meta || {},
    alias: one_45click_45unsubscribeUgT01mqYI0Meta?.alias || [],
    redirect: one_45click_45unsubscribeUgT01mqYI0Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tSCo0MpOCnMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93tSCo0MpOCnMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93tSCo0MpOCnMeta || {},
    alias: _91slug_93tSCo0MpOCnMeta?.alias || [],
    redirect: _91slug_93tSCo0MpOCnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JjJ49xDyyTMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93JjJ49xDyyTMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93JjJ49xDyyTMeta || {},
    alias: _91slug_93JjJ49xDyyTMeta?.alias || [],
    redirect: _91slug_93JjJ49xDyyTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___en-US",
    path: index1quA9Bg6aKMeta?.path ?? "/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___ca",
    path: index1quA9Bg6aKMeta?.path ?? "/ca/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___nl",
    path: index1quA9Bg6aKMeta?.path ?? "/nl/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___uk",
    path: index1quA9Bg6aKMeta?.path ?? "/uk/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___de",
    path: index1quA9Bg6aKMeta?.path ?? "/de/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___mx",
    path: index1quA9Bg6aKMeta?.path ?? "/mx/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___fr",
    path: index1quA9Bg6aKMeta?.path ?? "/fr/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index1quA9Bg6aKMeta?.name ?? "resources-experts___fr-ca",
    path: index1quA9Bg6aKMeta?.path ?? "/fr-ca/resources/experts",
    meta: index1quA9Bg6aKMeta || {},
    alias: index1quA9Bg6aKMeta?.alias || [],
    redirect: index1quA9Bg6aKMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___en-US",
    path: indexzIAUVcFPNhMeta?.path ?? "/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___ca",
    path: indexzIAUVcFPNhMeta?.path ?? "/ca/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___nl",
    path: indexzIAUVcFPNhMeta?.path ?? "/nl/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___uk",
    path: indexzIAUVcFPNhMeta?.path ?? "/uk/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___de",
    path: indexzIAUVcFPNhMeta?.path ?? "/de/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___mx",
    path: indexzIAUVcFPNhMeta?.path ?? "/mx/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___fr",
    path: indexzIAUVcFPNhMeta?.path ?? "/fr/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIAUVcFPNhMeta?.name ?? "resources___fr-ca",
    path: indexzIAUVcFPNhMeta?.path ?? "/fr-ca/resources",
    meta: indexzIAUVcFPNhMeta || {},
    alias: indexzIAUVcFPNhMeta?.alias || [],
    redirect: indexzIAUVcFPNhMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tk99ghg8pUMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_93tk99ghg8pUMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_93tk99ghg8pUMeta || {},
    alias: _91slug_93tk99ghg8pUMeta?.alias || [],
    redirect: _91slug_93tk99ghg8pUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937kmrwA1AANMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_937kmrwA1AANMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_937kmrwA1AANMeta || {},
    alias: _91slug_937kmrwA1AANMeta?.alias || [],
    redirect: _91slug_937kmrwA1AANMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___en-US",
    path: indexmoqMalFzpFMeta?.path ?? "/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___ca",
    path: indexmoqMalFzpFMeta?.path ?? "/ca/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___nl",
    path: indexmoqMalFzpFMeta?.path ?? "/nl/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___uk",
    path: indexmoqMalFzpFMeta?.path ?? "/uk/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___de",
    path: indexmoqMalFzpFMeta?.path ?? "/de/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___mx",
    path: indexmoqMalFzpFMeta?.path ?? "/mx/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___fr",
    path: indexmoqMalFzpFMeta?.path ?? "/fr/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexmoqMalFzpFMeta?.name ?? "resources-marketplace___fr-ca",
    path: indexmoqMalFzpFMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: indexmoqMalFzpFMeta || {},
    alias: indexmoqMalFzpFMeta?.alias || [],
    redirect: indexmoqMalFzpFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsara7s26ek8oQSMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsara7s26ek8oQSMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsara7s26ek8oQSMeta || {},
    alias: build_45with_45samsara7s26ek8oQSMeta?.alias || [],
    redirect: build_45with_45samsara7s26ek8oQSMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programscogphUxmoWMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programscogphUxmoWMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programscogphUxmoWMeta || {},
    alias: evolve_45insurance_45programscogphUxmoWMeta?.alias || [],
    redirect: evolve_45insurance_45programscogphUxmoWMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___en-US",
    path: indexuLyfYcNNlHMeta?.path ?? "/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___ca",
    path: indexuLyfYcNNlHMeta?.path ?? "/ca/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___nl",
    path: indexuLyfYcNNlHMeta?.path ?? "/nl/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___uk",
    path: indexuLyfYcNNlHMeta?.path ?? "/uk/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___de",
    path: indexuLyfYcNNlHMeta?.path ?? "/de/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___mx",
    path: indexuLyfYcNNlHMeta?.path ?? "/mx/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___fr",
    path: indexuLyfYcNNlHMeta?.path ?? "/fr/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexuLyfYcNNlHMeta?.name ?? "resources-partner-programs___fr-ca",
    path: indexuLyfYcNNlHMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: indexuLyfYcNNlHMeta || {},
    alias: indexuLyfYcNNlHMeta?.alias || [],
    redirect: indexuLyfYcNNlHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services3DVKo2keB7Meta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45services3DVKo2keB7Meta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45services3DVKo2keB7Meta || {},
    alias: provide_45services3DVKo2keB7Meta?.alias || [],
    redirect: provide_45services3DVKo2keB7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsarampIR689z6QMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsarampIR689z6QMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsarampIR689z6QMeta || {},
    alias: sell_45samsarampIR689z6QMeta?.alias || [],
    redirect: sell_45samsarampIR689z6QMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___en-US",
    path: tourLEhFGmUQznMeta?.path ?? "/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___ca",
    path: tourLEhFGmUQznMeta?.path ?? "/ca/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___nl",
    path: tourLEhFGmUQznMeta?.path ?? "/nl/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___uk",
    path: tourLEhFGmUQznMeta?.path ?? "/uk/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___de",
    path: tourLEhFGmUQznMeta?.path ?? "/de/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___mx",
    path: tourLEhFGmUQznMeta?.path ?? "/mx/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___fr",
    path: tourLEhFGmUQznMeta?.path ?? "/fr/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourLEhFGmUQznMeta?.name ?? "resources-tour___fr-ca",
    path: tourLEhFGmUQznMeta?.path ?? "/fr-ca/resources/tour",
    meta: tourLEhFGmUQznMeta || {},
    alias: tourLEhFGmUQznMeta?.alias || [],
    redirect: tourLEhFGmUQznMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___en-US",
    path: videosGvfFCMsgnLMeta?.path ?? "/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___ca",
    path: videosGvfFCMsgnLMeta?.path ?? "/ca/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___nl",
    path: videosGvfFCMsgnLMeta?.path ?? "/nl/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___uk",
    path: videosGvfFCMsgnLMeta?.path ?? "/uk/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___de",
    path: videosGvfFCMsgnLMeta?.path ?? "/de/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___mx",
    path: videosGvfFCMsgnLMeta?.path ?? "/mx/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___fr",
    path: videosGvfFCMsgnLMeta?.path ?? "/fr/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosGvfFCMsgnLMeta?.name ?? "resources-videos___fr-ca",
    path: videosGvfFCMsgnLMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosGvfFCMsgnLMeta || {},
    alias: videosGvfFCMsgnLMeta?.alias || [],
    redirect: videosGvfFCMsgnLMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___en-US",
    path: indexJU1L5jRmACMeta?.path ?? "/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___ca",
    path: indexJU1L5jRmACMeta?.path ?? "/ca/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___nl",
    path: indexJU1L5jRmACMeta?.path ?? "/nl/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___uk",
    path: indexJU1L5jRmACMeta?.path ?? "/uk/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___de",
    path: indexJU1L5jRmACMeta?.path ?? "/de/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___mx",
    path: indexJU1L5jRmACMeta?.path ?? "/mx/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___fr",
    path: indexJU1L5jRmACMeta?.path ?? "/fr/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexJU1L5jRmACMeta?.name ?? "roi-calculator___fr-ca",
    path: indexJU1L5jRmACMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexJU1L5jRmACMeta || {},
    alias: indexJU1L5jRmACMeta?.alias || [],
    redirect: indexJU1L5jRmACMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___de",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesSumIAIQkYTMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturesSumIAIQkYTMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturesSumIAIQkYTMeta || {},
    alias: samsara_45venturesSumIAIQkYTMeta?.alias || [],
    redirect: samsara_45venturesSumIAIQkYTMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___en-US",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___ca",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/ca/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___nl",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/nl/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___uk",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/uk/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___de",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/de/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___mx",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/mx/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___fr",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/fr/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summittP6rIjwYDpMeta?.name ?? "security-summit___fr-ca",
    path: security_45summittP6rIjwYDpMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summittP6rIjwYDpMeta || {},
    alias: security_45summittP6rIjwYDpMeta?.alias || [],
    redirect: security_45summittP6rIjwYDpMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___en-US",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___ca",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/ca/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___nl",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/nl/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___uk",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/uk/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___de",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/de/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___mx",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/mx/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___fr",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/fr/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dgVWfNQVa6Meta?.name ?? "solutions-prefix-slug___fr-ca",
    path: _91slug_93dgVWfNQVa6Meta?.path ?? "/fr-ca/solutions/:prefix()/:slug()",
    meta: _91slug_93dgVWfNQVa6Meta || {},
    alias: _91slug_93dgVWfNQVa6Meta?.alias || [],
    redirect: _91slug_93dgVWfNQVa6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___en-US",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___ca",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___nl",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___uk",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___de",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___mx",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___fr",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TKOFy2J9FGMeta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_93TKOFy2J9FGMeta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_93TKOFy2J9FGMeta || {},
    alias: _91slug_93TKOFy2J9FGMeta?.alias || [],
    redirect: _91slug_93TKOFy2J9FGMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___en-US",
    path: indexYPlKyiIJ4rMeta?.path ?? "/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___ca",
    path: indexYPlKyiIJ4rMeta?.path ?? "/ca/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___nl",
    path: indexYPlKyiIJ4rMeta?.path ?? "/nl/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___uk",
    path: indexYPlKyiIJ4rMeta?.path ?? "/uk/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___de",
    path: indexYPlKyiIJ4rMeta?.path ?? "/de/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___mx",
    path: indexYPlKyiIJ4rMeta?.path ?? "/mx/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___fr",
    path: indexYPlKyiIJ4rMeta?.path ?? "/fr/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexYPlKyiIJ4rMeta?.name ?? "solutions___fr-ca",
    path: indexYPlKyiIJ4rMeta?.path ?? "/fr-ca/solutions",
    meta: indexYPlKyiIJ4rMeta || {},
    alias: indexYPlKyiIJ4rMeta?.alias || [],
    redirect: indexYPlKyiIJ4rMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___en-US",
    path: securitygSLizbRiiMMeta?.path ?? "/solutions/security",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___ca",
    path: securitygSLizbRiiMMeta?.path ?? "/ca/solutions/security",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___nl",
    path: securitygSLizbRiiMMeta?.path ?? "/nl/solutions/security",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___uk",
    path: securitygSLizbRiiMMeta?.path ?? "/uk/solutions/security",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___de",
    path: securitygSLizbRiiMMeta?.path ?? "/de/solutions/security",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___mx",
    path: securitygSLizbRiiMMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___fr",
    path: securitygSLizbRiiMMeta?.path ?? "/fr/solutions/security",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securitygSLizbRiiMMeta?.name ?? "solutions-security___fr-ca",
    path: securitygSLizbRiiMMeta?.path ?? "/fr-ca/solutions/security",
    meta: securitygSLizbRiiMMeta || {},
    alias: securitygSLizbRiiMMeta?.alias || [],
    redirect: securitygSLizbRiiMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___en-US",
    path: spaceIbKPYvLXSMMeta?.path ?? "/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___ca",
    path: spaceIbKPYvLXSMMeta?.path ?? "/ca/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___nl",
    path: spaceIbKPYvLXSMMeta?.path ?? "/nl/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___uk",
    path: spaceIbKPYvLXSMMeta?.path ?? "/uk/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___de",
    path: spaceIbKPYvLXSMMeta?.path ?? "/de/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___mx",
    path: spaceIbKPYvLXSMMeta?.path ?? "/mx/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___fr",
    path: spaceIbKPYvLXSMMeta?.path ?? "/fr/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceIbKPYvLXSMMeta?.name ?? "space___fr-ca",
    path: spaceIbKPYvLXSMMeta?.path ?? "/fr-ca/space",
    meta: spaceIbKPYvLXSMMeta || {},
    alias: spaceIbKPYvLXSMMeta?.alias || [],
    redirect: spaceIbKPYvLXSMMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___en-US",
    path: sparkzCtk3DqEMHMeta?.path ?? "/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___ca",
    path: sparkzCtk3DqEMHMeta?.path ?? "/ca/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___nl",
    path: sparkzCtk3DqEMHMeta?.path ?? "/nl/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___uk",
    path: sparkzCtk3DqEMHMeta?.path ?? "/uk/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___de",
    path: sparkzCtk3DqEMHMeta?.path ?? "/de/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___mx",
    path: sparkzCtk3DqEMHMeta?.path ?? "/mx/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___fr",
    path: sparkzCtk3DqEMHMeta?.path ?? "/fr/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkzCtk3DqEMHMeta?.name ?? "spark___fr-ca",
    path: sparkzCtk3DqEMHMeta?.path ?? "/fr-ca/spark",
    meta: sparkzCtk3DqEMHMeta || {},
    alias: sparkzCtk3DqEMHMeta?.alias || [],
    redirect: sparkzCtk3DqEMHMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___en-US",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___ca",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___nl",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___uk",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___de",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___mx",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___fr",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fohd5uycLUMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93Fohd5uycLUMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93Fohd5uycLUMeta || {},
    alias: _91slug_93Fohd5uycLUMeta?.alias || [],
    redirect: _91slug_93Fohd5uycLUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AUbjG4AwEIMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93AUbjG4AwEIMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93AUbjG4AwEIMeta || {},
    alias: _91slug_93AUbjG4AwEIMeta?.alias || [],
    redirect: _91slug_93AUbjG4AwEIMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___en-US",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___ca",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___nl",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___uk",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___de",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___mx",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___fr",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ndqDmzE6UBMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93ndqDmzE6UBMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93ndqDmzE6UBMeta || {},
    alias: _91slug_93ndqDmzE6UBMeta?.alias || [],
    redirect: _91slug_93ndqDmzE6UBMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyTGzBhsC6K7Meta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyTGzBhsC6K7Meta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyTGzBhsC6K7Meta || {},
    alias: apps_45privacy_45policyTGzBhsC6K7Meta?.alias || [],
    redirect: apps_45privacy_45policyTGzBhsC6K7Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqS70yqE1E1JMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqS70yqE1E1JMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqS70yqE1E1JMeta || {},
    alias: customer_45referrals_45faqS70yqE1E1JMeta?.alias || [],
    redirect: customer_45referrals_45faqS70yqE1E1JMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish7wPKxwJ0odMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanish7wPKxwJ0odMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanish7wPKxwJ0odMeta || {},
    alias: driver_45training_45spanish7wPKxwJ0odMeta?.alias || [],
    redirect: driver_45training_45spanish7wPKxwJ0odMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___en-US",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___ca",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___nl",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___uk",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___de",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/de/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___mx",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___fr",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45traininggCpLid4DwgMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45traininggCpLid4DwgMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45traininggCpLid4DwgMeta || {},
    alias: driver_45traininggCpLid4DwgMeta?.alias || [],
    redirect: driver_45traininggCpLid4DwgMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___en-US",
    path: indexGLTTrb95lPMeta?.path ?? "/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___ca",
    path: indexGLTTrb95lPMeta?.path ?? "/ca/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___nl",
    path: indexGLTTrb95lPMeta?.path ?? "/nl/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___uk",
    path: indexGLTTrb95lPMeta?.path ?? "/uk/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___de",
    path: indexGLTTrb95lPMeta?.path ?? "/de/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___mx",
    path: indexGLTTrb95lPMeta?.path ?? "/mx/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___fr",
    path: indexGLTTrb95lPMeta?.path ?? "/fr/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexGLTTrb95lPMeta?.name ?? "support___fr-ca",
    path: indexGLTTrb95lPMeta?.path ?? "/fr-ca/support",
    meta: indexGLTTrb95lPMeta || {},
    alias: indexGLTTrb95lPMeta?.alias || [],
    redirect: indexGLTTrb95lPMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___en-US",
    path: industriallyXqBDcvITMeta?.path ?? "/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___ca",
    path: industriallyXqBDcvITMeta?.path ?? "/ca/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___nl",
    path: industriallyXqBDcvITMeta?.path ?? "/nl/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___uk",
    path: industriallyXqBDcvITMeta?.path ?? "/uk/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___de",
    path: industriallyXqBDcvITMeta?.path ?? "/de/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___mx",
    path: industriallyXqBDcvITMeta?.path ?? "/mx/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___fr",
    path: industriallyXqBDcvITMeta?.path ?? "/fr/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industriallyXqBDcvITMeta?.name ?? "support-industrial___fr-ca",
    path: industriallyXqBDcvITMeta?.path ?? "/fr-ca/support/industrial",
    meta: industriallyXqBDcvITMeta || {},
    alias: industriallyXqBDcvITMeta?.alias || [],
    redirect: industriallyXqBDcvITMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementnNouAIYMCXMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementnNouAIYMCXMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementnNouAIYMCXMeta || {},
    alias: modern_45slavery_45statementnNouAIYMCXMeta?.alias || [],
    redirect: modern_45slavery_45statementnNouAIYMCXMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyZIflTRUNTyMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyZIflTRUNTyMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyZIflTRUNTyMeta || {},
    alias: apps_45privacy_45policyZIflTRUNTyMeta?.alias || [],
    redirect: apps_45privacy_45policyZIflTRUNTyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___en-US",
    path: indexyFwbkOxSRmMeta?.path ?? "/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___ca",
    path: indexyFwbkOxSRmMeta?.path ?? "/ca/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___nl",
    path: indexyFwbkOxSRmMeta?.path ?? "/nl/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___uk",
    path: indexyFwbkOxSRmMeta?.path ?? "/uk/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___de",
    path: indexyFwbkOxSRmMeta?.path ?? "/de/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___mx",
    path: indexyFwbkOxSRmMeta?.path ?? "/mx/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___fr",
    path: indexyFwbkOxSRmMeta?.path ?? "/fr/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFwbkOxSRmMeta?.name ?? "support-privacy___fr-ca",
    path: indexyFwbkOxSRmMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexyFwbkOxSRmMeta || {},
    alias: indexyFwbkOxSRmMeta?.alias || [],
    redirect: indexyFwbkOxSRmMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___de",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresP9VyFDd1fnMeta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuresP9VyFDd1fnMeta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuresP9VyFDd1fnMeta || {},
    alias: special_45featuresP9VyFDd1fnMeta?.alias || [],
    redirect: special_45featuresP9VyFDd1fnMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listtLEntDpwoyMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listtLEntDpwoyMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listtLEntDpwoyMeta || {},
    alias: subprocessor_45listtLEntDpwoyMeta?.alias || [],
    redirect: subprocessor_45listtLEntDpwoyMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlestbIXKsxl1UMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlestbIXKsxl1UMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlestbIXKsxl1UMeta || {},
    alias: support_45kb_45articlestbIXKsxl1UMeta?.alias || [],
    redirect: support_45kb_45articlestbIXKsxl1UMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlestbIXKsxl1UMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlestbIXKsxl1UMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlestbIXKsxl1UMeta || {},
    alias: support_45kb_45articlestbIXKsxl1UMeta?.alias || [],
    redirect: support_45kb_45articlestbIXKsxl1UMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlestbIXKsxl1UMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlestbIXKsxl1UMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlestbIXKsxl1UMeta || {},
    alias: support_45kb_45articlestbIXKsxl1UMeta?.alias || [],
    redirect: support_45kb_45articlestbIXKsxl1UMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlestbIXKsxl1UMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlestbIXKsxl1UMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlestbIXKsxl1UMeta || {},
    alias: support_45kb_45articlestbIXKsxl1UMeta?.alias || [],
    redirect: support_45kb_45articlestbIXKsxl1UMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagebY3EgAqEAFMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pagebY3EgAqEAFMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pagebY3EgAqEAFMeta || {},
    alias: thank_45you_45pagebY3EgAqEAFMeta?.alias || [],
    redirect: thank_45you_45pagebY3EgAqEAFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingZEEcdBQibFMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingZEEcdBQibFMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingZEEcdBQibFMeta || {},
    alias: thankyou_45recruitingZEEcdBQibFMeta?.alias || [],
    redirect: thankyou_45recruitingZEEcdBQibFMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralfXVMNuF7IdMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralfXVMNuF7IdMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralfXVMNuF7IdMeta || {},
    alias: thankyou_45referralfXVMNuF7IdMeta?.alias || [],
    redirect: thankyou_45referralfXVMNuF7IdMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___en-US",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___ca",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/ca/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___nl",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/nl/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___uk",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/uk/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___de",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/de/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___mx",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/mx/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___fr",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/fr/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoubiCBFs4W8YMeta?.name ?? "thankyou___fr-ca",
    path: thankyoubiCBFs4W8YMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyoubiCBFs4W8YMeta || {},
    alias: thankyoubiCBFs4W8YMeta?.alias || [],
    redirect: thankyoubiCBFs4W8YMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: eliteuDLhu14iAjMeta?.name ?? "tiers-elite___uk",
    path: eliteuDLhu14iAjMeta?.path ?? "/uk/tiers/elite",
    meta: eliteuDLhu14iAjMeta || {},
    alias: eliteuDLhu14iAjMeta?.alias || [],
    redirect: eliteuDLhu14iAjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteuDLhu14iAjMeta?.name ?? "tiers-elite___en-US",
    path: eliteuDLhu14iAjMeta?.path ?? "/tiers/elite",
    meta: eliteuDLhu14iAjMeta || {},
    alias: eliteuDLhu14iAjMeta?.alias || [],
    redirect: eliteuDLhu14iAjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteuDLhu14iAjMeta?.name ?? "tiers-elite___ca",
    path: eliteuDLhu14iAjMeta?.path ?? "/ca/tiers/elite",
    meta: eliteuDLhu14iAjMeta || {},
    alias: eliteuDLhu14iAjMeta?.alias || [],
    redirect: eliteuDLhu14iAjMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: pluseGl8z4y8o4Meta?.name ?? "tiers-plus___uk",
    path: pluseGl8z4y8o4Meta?.path ?? "/uk/tiers/plus",
    meta: pluseGl8z4y8o4Meta || {},
    alias: pluseGl8z4y8o4Meta?.alias || [],
    redirect: pluseGl8z4y8o4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: pluseGl8z4y8o4Meta?.name ?? "tiers-plus___en-US",
    path: pluseGl8z4y8o4Meta?.path ?? "/tiers/plus",
    meta: pluseGl8z4y8o4Meta || {},
    alias: pluseGl8z4y8o4Meta?.alias || [],
    redirect: pluseGl8z4y8o4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: pluseGl8z4y8o4Meta?.name ?? "tiers-plus___ca",
    path: pluseGl8z4y8o4Meta?.path ?? "/ca/tiers/plus",
    meta: pluseGl8z4y8o4Meta || {},
    alias: pluseGl8z4y8o4Meta?.alias || [],
    redirect: pluseGl8z4y8o4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierSI6XfkQrC6Meta?.name ?? "tiers-premier___uk",
    path: premierSI6XfkQrC6Meta?.path ?? "/uk/tiers/premier",
    meta: premierSI6XfkQrC6Meta || {},
    alias: premierSI6XfkQrC6Meta?.alias || [],
    redirect: premierSI6XfkQrC6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierSI6XfkQrC6Meta?.name ?? "tiers-premier___en-US",
    path: premierSI6XfkQrC6Meta?.path ?? "/tiers/premier",
    meta: premierSI6XfkQrC6Meta || {},
    alias: premierSI6XfkQrC6Meta?.alias || [],
    redirect: premierSI6XfkQrC6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierSI6XfkQrC6Meta?.name ?? "tiers-premier___ca",
    path: premierSI6XfkQrC6Meta?.path ?? "/ca/tiers/premier",
    meta: premierSI6XfkQrC6Meta || {},
    alias: premierSI6XfkQrC6Meta?.alias || [],
    redirect: premierSI6XfkQrC6Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starternD0TMlFbsUMeta?.name ?? "tiers-starter___uk",
    path: starternD0TMlFbsUMeta?.path ?? "/uk/tiers/starter",
    meta: starternD0TMlFbsUMeta || {},
    alias: starternD0TMlFbsUMeta?.alias || [],
    redirect: starternD0TMlFbsUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starternD0TMlFbsUMeta?.name ?? "tiers-starter___en-US",
    path: starternD0TMlFbsUMeta?.path ?? "/tiers/starter",
    meta: starternD0TMlFbsUMeta || {},
    alias: starternD0TMlFbsUMeta?.alias || [],
    redirect: starternD0TMlFbsUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starternD0TMlFbsUMeta?.name ?? "tiers-starter___ca",
    path: starternD0TMlFbsUMeta?.path ?? "/ca/tiers/starter",
    meta: starternD0TMlFbsUMeta || {},
    alias: starternD0TMlFbsUMeta?.alias || [],
    redirect: starternD0TMlFbsUMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___en-US",
    path: processnLvepd2Bz8Meta?.path ?? "/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___ca",
    path: processnLvepd2Bz8Meta?.path ?? "/ca/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___nl",
    path: processnLvepd2Bz8Meta?.path ?? "/nl/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___uk",
    path: processnLvepd2Bz8Meta?.path ?? "/uk/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___de",
    path: processnLvepd2Bz8Meta?.path ?? "/de/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___mx",
    path: processnLvepd2Bz8Meta?.path ?? "/mx/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___fr",
    path: processnLvepd2Bz8Meta?.path ?? "/fr/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processnLvepd2Bz8Meta?.name ?? "trial-process___fr-ca",
    path: processnLvepd2Bz8Meta?.path ?? "/fr-ca/trial/process",
    meta: processnLvepd2Bz8Meta || {},
    alias: processnLvepd2Bz8Meta?.alias || [],
    redirect: processnLvepd2Bz8Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: support71hxqgk7SvMeta?.name ?? "warranty-support___uk",
    path: support71hxqgk7SvMeta?.path ?? "/uk/warranty/support",
    meta: support71hxqgk7SvMeta || {},
    alias: support71hxqgk7SvMeta?.alias || [],
    redirect: support71hxqgk7SvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: support71hxqgk7SvMeta?.name ?? "warranty-support___en-US",
    path: support71hxqgk7SvMeta?.path ?? "/warranty/support",
    meta: support71hxqgk7SvMeta || {},
    alias: support71hxqgk7SvMeta?.alias || [],
    redirect: support71hxqgk7SvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: support71hxqgk7SvMeta?.name ?? "warranty-support___ca",
    path: support71hxqgk7SvMeta?.path ?? "/ca/warranty/support",
    meta: support71hxqgk7SvMeta || {},
    alias: support71hxqgk7SvMeta?.alias || [],
    redirect: support71hxqgk7SvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: support71hxqgk7SvMeta?.name ?? "warranty-support___ie",
    path: support71hxqgk7SvMeta?.path ?? "/ie/warranty/support",
    meta: support71hxqgk7SvMeta || {},
    alias: support71hxqgk7SvMeta?.alias || [],
    redirect: support71hxqgk7SvMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___de",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vN4HkXfu5lMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93vN4HkXfu5lMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93vN4HkXfu5lMeta || {},
    alias: _91slug_93vN4HkXfu5lMeta?.alias || [],
    redirect: _91slug_93vN4HkXfu5lMeta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___en-US",
    path: indexs8Yma2MWk9Meta?.path ?? "/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___ca",
    path: indexs8Yma2MWk9Meta?.path ?? "/ca/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___nl",
    path: indexs8Yma2MWk9Meta?.path ?? "/nl/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___uk",
    path: indexs8Yma2MWk9Meta?.path ?? "/uk/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___de",
    path: indexs8Yma2MWk9Meta?.path ?? "/de/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___mx",
    path: indexs8Yma2MWk9Meta?.path ?? "/mx/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___fr",
    path: indexs8Yma2MWk9Meta?.path ?? "/fr/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexs8Yma2MWk9Meta?.name ?? "webinars___fr-ca",
    path: indexs8Yma2MWk9Meta?.path ?? "/fr-ca/webinars",
    meta: indexs8Yma2MWk9Meta || {},
    alias: indexs8Yma2MWk9Meta?.alias || [],
    redirect: indexs8Yma2MWk9Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bB98LtK8z4Meta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93bB98LtK8z4Meta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93bB98LtK8z4Meta || {},
    alias: _91slug_93bB98LtK8z4Meta?.alias || [],
    redirect: _91slug_93bB98LtK8z4Meta?.redirect,
    component: () => import("/codebuild/output/src4016038410/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubdecMsWe93GMeta?.name ?? undefined,
    path: component_45stubdecMsWe93GMeta?.path ?? "/sitemap.xml",
    meta: component_45stubdecMsWe93GMeta || {},
    alias: component_45stubdecMsWe93GMeta?.alias || [],
    redirect: component_45stubdecMsWe93GMeta?.redirect,
    component: component_45stubdecMsWe93G
  }
]